import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s12_2_3 : false,
      s12_2_4 : false,
      s13_2_3 : false
    }

    this.searchInit = {
      filter1Init: 6,
      filter2Init: 0,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>蓄電池サプライチェーンにおける国別市場シェア</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s12_2_3"}>
                <img src={'../../images/chart/s12-2-3.png'} alt="蓄電池サプライチェーンにおける国別市場シェア" width={559} />
              </button>
              <ImageBox
                open={this.state.s12_2_3}
                close={this.closeModal}
                name={"s12_2_3"}
                src={'/images/chart/s12-2-3.png'}
                alt="蓄電池サプライチェーンにおける国別市場シェア"
                width={1196}
                height={486} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/global-ev-outlook-2022" target="_blank" rel="noreferrer noopener">IEA, “Global EV Outlook 2022” を基にNEDOが作成</a></p>
                <p className={chartStyles.chartExplain}>蓄電池のサプライチェーンにおける原料・各プロセス等における国別市場シェアを示す<br />
                Mining：採掘量のシェア<br />
                Material processing：精錬量のシェア<br />
                Cell Component：正極材、負極材の製造量シェア<br />
                Battery Cells：蓄電池製造量のシェア<br />
                Evs：EV生産台数のシェア</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>リチウムイオン電池の地域別のリサイクル能力</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s12_2_4"}>
                <img src={'../../images/chart/s12-2-4.png'} alt="リチウムイオン電池の地域別のリサイクル能力" width={559} />
              </button>
              <ImageBox
                open={this.state.s12_2_4}
                close={this.closeModal}
                name={"s12_2_4"}
                src={'/images/chart/s12-2-4.png'}
                alt="リチウムイオン電池の地域別のリサイクル能力"
                width={559}
                height={381} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/the-role-of-critical-minerals-in-clean-energy-transitions" target="_blank" rel="noreferrer noopener">IEA, “The role of Critical Minerals in Clean Energy Transitions” を基にNEDOが作成</a></p>
                <p className={chartStyles.chartExplain}>2021年時点の既存設備や公表済の計画に基づく地域別のリチウムイオン電池のリサイクル能力を示す</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>Society of Automotive Engineers(SAE)による自動運転レベル</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s13_2_3"}>
                <img src={'../../images/chart/s13-2-3.png'} alt="Society of Automotive Engineers(SAE)による自動運転レベル" width={559} />
              </button>
              <ImageBox
                open={this.state.s13_2_3}
                close={this.closeModal}
                name={"s13_2_3"}
                src={'/images/chart/s13-2-3.png'}
                alt="Society of Automotive Engineers(SAE)による自動運転レベル"
                width={1192}
                height={920} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.sae.org/blog/sae-j3016-update" target="_blank" rel="noreferrer noopener">SAE INTERNATIONAL”SAE Levels of Driving Automation™ Refined for Clarity and International Audience”</a></p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`